import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
  Box,
  IconButton,
  CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const AppPopup = ({ open, handleClose, pitId }) => {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    confirmEmail: ''
  });

  const [success, setSuccess] = useState(false);
  const [validationError, setValidationError] = useState('');
  const [error, setError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (!open) {
      setFormData({
        first_name: '',
        last_name: '',
        email: '',
        confirmEmail: ''
      });
      setError(false);
      setValidationError('');
      setSuccess(false);
      setIsSubmitting(false);
    }
  }, [open]);

  const baseURL =
      process.env.REACT_APP_BACKEND_BASE_URL ||
      "https://vision-pit-backend-dot-seismic-helper-364013.nw.r.appspot.com";

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.email !== formData.confirmEmail) {
      setValidationError('Email and Confirm Email do not match.');
      return;
    }

    const submissionData = {
      first_name: formData.first_name,
      last_name: formData.last_name,
      email: formData.email,
      pit_associate: pitId
    };

    setIsSubmitting(true); // Start loading animation

    // Post form data to API
    try {
      const response = await fetch(`${baseURL}/create_app_user/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(submissionData)
      });

      if (response.ok) {
        const currentUrl = window.location.pathname;
        const pathSegments = currentUrl.split("/");
        const newUrl =  `${window.location.origin}/` + pathSegments?.[1] + "/success";

        window.history.pushState({}, "", newUrl);
      } else {
        console.error('Error submitting form');
        setError(true);
        setTimeout(() => {
          setError(false);
          handleClose();
        }, 3000);
      }
    } catch (error) {
      setError(true);
      console.error('Error submitting form:', error);
      setTimeout(() => {
        setError(false);
        handleClose();
      }, 3000);
    } finally {
      setIsSubmitting(false); // Stop loading animation
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        minWidth: "sm",
        "& .MuiDialog-paper": {
          borderRadius: "25px",
          border: `3px solid #81385B`, // Add border color
        },
      }}
    >
      <DialogContent sx={{ marginLeft: '20px', marginRight: '20px', alignItems: 'center', justifyContent: 'center' }}>
        {success ? (
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <img
              src="/assets/registeration-success.svg"
              alt="Success"
              style={{ width: '150px', height: '150px', alignContent: 'center', justifyContent: 'center', alignItems: 'center' }}
            />
            <Typography variant="h6" color="success" sx={{ fontFamily: 'Montserrat', marginTop: '20px' }}>
              Registered successfully!
            </Typography>
          </div>
        ) : error ? (
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <img
              src="/assets/registeration-failed.svg"
              alt="Failed"
              style={{ width: '150px', height: '150px', alignContent: 'center', justifyContent: 'center', alignItems: 'center' }}
            />
            <Typography variant="h6" color="error" sx={{ fontFamily: 'Montserrat', marginTop: '20px', textAlign: 'center' }}>
              Registration failed!<br />
              Please try again later.
            </Typography>
          </div>
        ) : (
          <>
            <IconButton onClick={handleClose} sx={{ color: '#81385B', position: 'absolute', top: 8, right: 8 }}>
              <CloseIcon />
            </IconButton>
            <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            fontFamily: "Montserrat",
            fontWeight: 800,
            fontSize: "24px",
            color: "#81385B",
            textAlign: "center",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
         Get the Holiday App now
        </Box>
            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                margin="normal"
                label="First name"
                name="first_name"
                value={formData.first_name}
                onChange={handleChange}
                required
              />
              <TextField
                fullWidth
                margin="normal"
                label="Last name"
                name="last_name"
                value={formData.last_name}
                onChange={handleChange}
                required
              />
              <TextField
                fullWidth
                margin="normal"
                label="Email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <TextField
                fullWidth
                margin="normal"
                label="Confirm email"
                name="confirmEmail"
                type="email"
                value={formData.confirmEmail}
                onChange={handleChange}
                required
              />
              {validationError && (
                <Typography variant="body2" color="error" sx={{ fontFamily: 'Montserrat', fontWeight: 800 }}>
                  {validationError}
                </Typography>
              )}
              <DialogActions style={{ justifyContent: "center", marginTop: '20px' }}>
              <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    backgroundColor: "#81385B",
                    fontSize: "20px",
                    fontFamily: "Montserrat",
                    borderRadius: 100,
                    width: "250px",
                    height: "56px",
                    "&:hover": { backgroundColor: "#9A5F7B" },
                  }}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <CircularProgress size={24} />
                  ) :  (
                    "Send me the App"
                  )}
                </Button>
              </DialogActions>
            </form>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AppPopup;
